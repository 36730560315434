var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{staticClass:"container-bg",staticStyle:{"width":"730px","margin-top":"1%","margin":"auto"}},[_c('div',{staticClass:"container-fg"},[_c('div',{staticStyle:{"display":"flex","flex-direction":"row"}},[_c('img',{staticStyle:{"width":"130px","margin-bottom":"1%"},attrs:{"src":require("../../assets/logos/getac-logo.svg")}}),_c('div',{staticClass:"text-h7 nav-font pl-3",staticStyle:{"margin-top":"1.3%"}},[_vm._v("PARTS ORDER")])]),_c('v-divider',{staticStyle:{"margin-bottom":"1%","border-color":"gray"}}),_c('h1',{staticStyle:{"font-size":"36px","letter-spacing":"-0.08rem"}},[_vm._v(" Your Cart ")]),_c('p',[_vm._v("Confirm or remove cart items below.")]),_c('div',{staticStyle:{"margin-top":"-10px"}},[_c('p',{staticStyle:{"font-size":"10px","margin-left":"5px"}},[_c('span',{staticStyle:{"color":"#db5120"}},[_vm._v("*")]),_vm._v(" indicates a high value part.")])]),_c('div',{staticStyle:{"display":"flex","flex-direction":"row","justify-content":"right","margin-top":"-85px"}},[_c('v-btn',{staticClass:"mt-5 mr-5",attrs:{"color":"primary","elevation":"0","outlined":"","width":"150px"},on:{"click":_vm.goBack}},[_vm._v(" Back ")]),_c('v-btn',{staticClass:"mt-5",attrs:{"color":"primary","width":"150px","elevation":"0"},on:{"click":_vm.toCheckout}},[_vm._v(" Checkout ")])],1),_c('v-divider',{staticStyle:{"margin-bottom":"1%","margin-top":"4%","border-color":"gray"}}),_c('v-data-table',{staticClass:"text-h6 nav-font pl-3",staticStyle:{"background-color":"transparent","text-transform":"uppercase","font-weight":"500"},attrs:{"dark":"","headers":_vm.headers,"hide-default-footer":"","disable-pagination":"","disable-sort":"","items":_vm.cart,"width":"","loading-text":"Loading...","no-data-text":"No items in cart"},scopedSlots:_vm._u([{key:"item.imgUrl",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticClass:"parts-image",attrs:{"src":"https://rma.getac.com" + item.imgUrl,"height":"100px"}})]}},{key:"item.partSpec",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.partSpec)+" "),(item.isHighValue === 'Y')?_c('span',{staticStyle:{"color":"#db5120"}},[_vm._v("*")]):_vm._e()]}},{key:"item.serial",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.serials)+" ")]}},{key:"item.remove",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","outlined":"","elevation":"0"},on:{"click":function($event){$event.stopPropagation();return _vm.remove(Object.assign({}, item))}}},[_vm._v("Remove")])]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }
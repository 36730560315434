<template>
    <v-container>
        <div
            class="container-bg"
            style="width: 730px; margin-top: 1%; margin: auto"
        >
            <div class="container-fg">
                <div style="display: flex; flex-direction: row">
                    <img
                        style="width: 130px; margin-bottom: 1%"
                        src="../../assets/logos/getac-logo.svg"
                    />
                    <div class="text-h7 nav-font pl-3" style="margin-top: 1.3%"
                        >PARTS ORDER</div
                    >
                </div>
                <v-divider style="margin-bottom: 1%; border-color: gray" />
                <h1 style="font-size: 36px; letter-spacing: -0.08rem">
                    Your Cart
                </h1>
                <p>Confirm or remove cart items below.</p>
                <div style="margin-top: -10px">
                    <p style="font-size: 10px; margin-left: 5px"
                        ><span style="color: #db5120">*</span> indicates a high
                        value part.</p
                    >
                </div>
                <div
                    style="
                        display: flex;
                        flex-direction: row;
                        justify-content: right;
                        margin-top: -85px;
                    "
                >
                    <v-btn
                        color="primary"
                        elevation="0"
                        outlined
                        width="150px"
                        class="mt-5 mr-5"
                        @click="goBack"
                    >
                        Back
                    </v-btn>
                    <v-btn
                        color="primary"
                        width="150px"
                        elevation="0"
                        class="mt-5"
                        @click="toCheckout"
                    >
                        Checkout
                    </v-btn>
                </div>
                <v-divider
                    style="
                        margin-bottom: 1%;
                        margin-top: 4%;
                        border-color: gray;
                    "
                />
                <v-data-table
                    style="
                        background-color: transparent;
                        text-transform: uppercase;
                        font-weight: 500;
                    "
                    dark
                    class="text-h6 nav-font pl-3"
                    :headers="headers"
                    hide-default-footer
                    disable-pagination
                    disable-sort
                    :items="cart"
                    width
                    loading-text="Loading..."
                    no-data-text="No items in cart"
                >
                    <template v-slot:item.imgUrl="{ item }">
                        <v-img
                            class="parts-image"
                            :src="`https://rma.getac.com` + item.imgUrl"
                            height="100px"
                        ></v-img>
                    </template>
                    <template v-slot:item.partSpec="{ item }">
                        {{ item.partSpec }}
                        <span
                            v-if="item.isHighValue === 'Y'"
                            style="color: #db5120"
                            >*</span
                        >
                    </template>
                    <template v-slot:item.serial="{ item }">
                        {{ item.serials }}
                    </template>
                    <template v-slot:item.remove="{ item }">
                        <v-btn
                            color="primary"
                            outlined
                            elevation="0"
                            @click.stop="
                                remove({
                                    ...item
                                })
                            "
                            >Remove</v-btn
                        >
                    </template>
                </v-data-table>
            </div>
        </div>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    name: `PartsOrderCart`,
    components: {},
    props: {
        actions: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            updatedCart: [],
            headers: [
                {
                    text: 'Image',
                    align: 'start',
                    value: 'imgUrl'
                },
                {
                    text: 'Product',
                    align: 'center',
                    value: 'partSpec'
                },
                {
                    text: 'Serial',
                    align: 'center',
                    value: 'serial'
                },
                {
                    text: 'Model',
                    align: 'center',
                    value: 'model'
                },
                {
                    text: 'Action',
                    align: 'center',
                    value: 'remove'
                }
            ]
        }
    },
    computed: {
        ...mapGetters({
            cart: `cart/cart`
        }),
        combinedPartsCart() {
            const currentCart = this.cart
            const newCart = [
                ...new Set(currentCart.map((lineItem) => lineItem.pn))
            ].map((pn) => {
                return {
                    pn,
                    quantity: currentCart.filter(
                        (lineItem) => lineItem.pn === pn
                    ).length,
                    parts: currentCart.filter((lineItem) => lineItem.pn === pn)
                }
            })
            console.log(newCart)
            return newCart
        }
    },
    methods: {
        ...mapActions({
            remove: `cart/remove`
        }),
        toCheckout() {
            this.$router.push({ path: '/parts-order/checkout' })
        },
        goBack() {
            this.$router.push({
                name: 'parts-order',
                params: { step: 2 }
            })
        }
    }
}
</script>

<style scoped>
.parts-image {
    margin-top: 5%;
    margin-bottom: 5%;
    height: auto;
    max-width: 120px;
}
</style>
